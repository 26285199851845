/* eslint-disable linebreak-style */
import { getBaseReducer } from 'utils';
import { accountModel as model } from 'models/account';
import actions from './actions';

const initState = {
  ...getBaseReducer(model),
  entityType: 'accounts',
  source: [],
  ttlData: {},
  filters: {
    search: '',
  },
  accounts: [],
  showTTLForm: false,
  loadingTTLDetails: false,
  showDefaultSpeechDialog: false,
  fetchdefaultLanguageModel: true,
  defaultLanguageModel: [],
  predictiveModels: [],
  loading: false,
  etlNotificationDialog: false,
  missingRecords: [],
  skippedRecords: [],
  categorizationJobs: null
};

const getTTLData = (ttl = {}) => ({
  analyticsIndexTtlInMinutes: `${Math.floor(
    (ttl.analyticsIndexTtlInMinutes || 0) / 1440,
  )} Days`,
  defaultMediaTtlInMinutes: `${Math.floor(
    (ttl.defaultMediaTtlInMinutes || 0) / 1440,
  )} Days`,
});

export default function accountReducer(state = initState, action) {
  switch (action.type) {
    case actions.CLEAR_ACCOUNTS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_ACCOUNTS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_ACCOUNT_OPTIONS_SUCCESS:
      return {
        ...state,
        source: action.data,
      };
    case actions.SET_ACCESS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.accounts,
      };
    case actions.FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        source: action.data,
        init: true,
      };
    case actions.FETCH_PLATFORM_ACCOUNT:
      return {
        ...state,
        isFetchingAccountFromPlatform: true,
      };
    case actions.FETCH_PLATFORM_ACCOUNT_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItemFromPlatform,
        isFetchingAccountFromPlatform: false,
      };
    case actions.FETCH_PLATFORM_ACCOUNT_FAILURE:
      return {
        ...state,
        selectedItem: {},
        isFetchingAccountFromPlatform: false,
        isFetchingAccountFromPlatformFailure: true,
      };
    case actions.FILTER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        filters: action.filters,
        view: false,
      };
    case actions.SELECT_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SELECT_ACCOUNT_SUCCESS:
      return {
        ...state,
        ttlData: {},
        isLoading: false,
        selectedItem: action.selectedItem,
      };
    case actions.FETCH_ACCOUNT_ETL_CONFIG_SUCCESS:
      return {
        ...state,
        selectedItem: { ...action.data },
      };
    case actions.TOGGLE_ACCOUNT_IS_LOADING:
      return {
        ...state,
       isLoading: action.data
      };
    case actions.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        source: [action.selectedItem, ...state.data],
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.EDIT_ACCOUNT_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        showDefaultSpeechDialog: false,
        view: false,
        isLoading: false,
      };
    case actions.EDIT_ACCOUNT:
      return {
        ...state,
        // isLoading: true,
        showDefaultSpeechDialog: false,
        selectedItem: action.data,
      };
    case actions.EDIT_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        fetchdefaultLanguageModel: true,
      };
    case actions.EDIT_MY_ACCOUNT:
      return {
        ...state,
        showDefaultSpeechDialog: false,
        isLoading: true,
      };
    case actions.EDIT_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        showDefaultSpeechDialog: false,
        isLoading: false,
      };
    case actions.EDIT_MY_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        fetchdefaultLanguageModel: true,
      };
    case actions.DELETE__ACCOUNT:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_ACCOUNT_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || state.selectedItem || {},
        view: !state.view,
        focusTab: action.focusOnTab,
      };
    case actions.CHANGE_ACCOUNT_FOCUS_TAB:
      return {
        ...state,
        focusTab: action.focusOnTab,
      };
    case actions.REFRESH_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.data || state.data,
        selectedItem: action.selectedItem || state.selectedItem || {},
      };
    case actions.FETCH_TTL:
      return {
        ...state,
        loadingTTLDetails: true,
      };
    case actions.FETCH_TTL_SUCCESS:
      return {
        ...state,
        ttlData: getTTLData(action.data),
        loadingTTLDetails: false,
      };
    case actions.FETCH_TTL_FAILURE:
      return {
        ...state,
        ttlData: {},
        loadingTTLDetails: false,
      };
    case actions.UPDATE_TTL:
      return {
        ...state,
        updatingTTL: true,
      };
    case actions.UPDATE_TTL_SUCCESS:
      return {
        ...state,
        ttlData: getTTLData(action.data),
        // showTTLForm: false,
        updatingTTL: false,
      };
    case actions.UPDATE_TTL_FAILURE:
      return {
        ...state,
        updatingTTL: false,
      };
    case actions.SHOW_TTL_FORM:
      return {
        ...state,
        showTTLForm: !state.showTTLForm,
      };
    case actions.FETCH_DEFAULT_SPEECH_MODEL:
      return {
        ...state,
        loadingSpeechModel: true,
      };
    case actions.FETCH_DEFAULT_SPEECH_MODEL_SUCCESS:
      return {
        ...state,
        speechModel: action.data,
        loadingSpeechModel: false,
      };
    case actions.FETCH_DEFAULT_SPEECH_MODEL_FAILURE:
      return {
        ...state,
        speechModel: {},
        loadingSpeechModel: false,
      };
    case actions.CHANGE_ACCOUNT_VIEW_FOR_IMPORT:
      return {
        ...state,
        view: !state.view,
        isImportAccountFlow: !state.isImportAccountFlow,
        isFetchingAccountFromPlatformFailure: false,
        isFetchingAccountFromPlatform: false,
        selectedItem: null,
      };
    case actions.GET_SPEECH_DEFAULT_LANGUAGE_SUCCESS:
      return {
        ...state,
        defaultLanguageModel: [...state.defaultLanguageModel, action.data],
      };
    case actions.SHOW_DEFAULT_SPEECH_DIALOG:
      return {
        ...state,
        showDefaultSpeechDialog: !state.showDefaultSpeechDialog,
        add: action.add,
      };
    case actions.CLEAR_DEFAULT_LANGS_MODEL:
      return {
        ...state,
        defaultLanguageModel: [],
      };
    case actions.GET_PREDICTIVE_MODEL_SUCCESS:
      return {
        ...state,
        predictiveModels: action.data,
      };
    case actions.FETCH_DEFAULT_LANGUAGE_MODEL:
      return {
        ...state,
        fetchdefaultLanguageModel: action.status,
      };
    case actions.CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: {},
      };
    case actions.ETL_NOTIFICATION_DIALOG:
      return {
        ...state,
        etlNotificationDialog: action.data,
      };
    case actions.FETCH_MISSING_RECORDS_SUCCESS:
      return {
        ...state,
        missingRecords: action.data
      }

    case actions.FETCH_MISSING_RECORDS_FAILURE:
      return {
        ...state,
      }

    case actions.FETCH_SKIPPED_RECORDS_SUCCESS:
      return {
        ...state,
        skippedRecords: action.data
      }
    case actions.FETCH_SKIPPED_RECORDS_FAILURE:
      return {
        ...state,
      }

    case actions.CHANGE_ACCOUNT_FORM_VIEW:
      return {
        ...state,
        view: action.data
      }

    case actions.FETCH_CATEGORIZATION_JOBS_SUCCESS:
      return {
        ...state,
        categorizationJobs: action.data
      }

    default:
      return state;
  }
}
