/** @prettier */
import React from 'react';
import { Field as FField, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { withLabel } from './withLabel';
import './css/input-with-icon.css';

export const Error = styled.div`
  color: #d75118;
  margin-top: 5px;
  font-size: 12px;
`;
//
const InputWithIco = ({
  field,
  value,
  icon,
  rows = '1',
  component = 'input',
  placeholder = '',
  style = {},
  children = null,
  classNames = '',
  disabled = false,
  onBlur,
  validate = {},
  type = 'text',
  min: minLength,
  onFocus,
  id,
  autoComplete,
}) => (
  <>
    <div className="input-icon-wrapper">
      {icon && <div className="icon">{icon}</div>}
      <div className="input">
        <FField
          component={component}
          validate={validate}
          rows={rows}
          type={type}
          name={field}
          className={`k-textbox ${classNames}`}
          value={value}
          placeholder={placeholder}
          style={style}
          disabled={disabled}
          {...(autoComplete && { autoComplete })}
          {...(onBlur && { onBlur })}
          {...(onFocus && { onFocus })}
          {...(id && { id })}
          {...(minLength && { minLength })}
          {...(type === 'number' && { min: 0 })}
        >
          {children}
        </FField>
      </div>
    </div>
    <ErrorMessage name={field} className="form-error" component={Error} />
  </>
);
export const InputWithIcon = withLabel(InputWithIco);
