/** @prettier */
import { all, takeEvery, fork, call, put, select } from 'redux-saga/effects';
import api from 'services';
import _, { cloneDeep } from 'lodash';
import { getAuthenticatedUser } from 'redux/users/saga';
import userActions from 'redux/users/actions';
import actions from './actions';
import pciFlag from '../pciflag/actions';
import { transformConnections, prepConnection, editConnectionPrep } from '../../transform/connection';

const entityType = 'connections';

export function* changeConnectionViewSaga() {
  yield takeEvery(actions.CHANGE_CONNECTION_VIEW, function* ({ selectedItem }) {
    if (selectedItem) {
      const data = yield call(api.fetchItem, { entityType, id: selectedItem._id });
      if (data) {
        yield put({ type: actions.CHANGE_CONNECTION_VIEW_SUCCESS, selectedItem: { ...data, id: data._id } });
      }
    } else {
      yield put({ type: actions.CHANGE_CONNECTION_VIEW_SUCCESS, selectedItem: {} });
    }
  });
}

export function* fetchConnectionsSaga() {
  yield takeEvery(actions.FETCH_CONNECTIONS, function* () {
    const data = yield call(api.fetchAll, { entityType, transform: transformConnections });
    if (data) {
      const vendorsUsed = data?.map((x) => x.vendor);
      yield put({ type: actions.FETCH_CONNECTIONS_SUCCESS, data, vendorsUsed });
    }
  });
}

export function* addAgentStatusConnection() {
  yield takeEvery(actions.ADD_AGENT_STATUS, function* ({ data, onFinish }) {
    const connection = yield call(api.createItem, {
      entityType,
      data: { ...data, source: null, interactionType: 'AGENTSTATUS', configuration: '{}' },
    });
    yield put({ type: actions.EDIT_CONNECTION_SUCCESS, selectedItem: connection });
    yield put({ type: actions.FETCH_CONNECTIONS });
    onFinish();
  });
}

export function* addConnectionsaga() {
  yield takeEvery(actions.ADD_CONNECTION, function* ({ data, onFinish }) {
    let source = !!_.get(data, 'source.id');
    if (!source) {
      const sources = yield call(api.fetchAll, { entityType: 'dataSources' });
      if (sources) {
        source = sources.find((x) => x.name === 'defaultConnection');
      }
      if (!source) {
        source = yield call(api.createItem, {
          entityType: 'dataSources',
          data: { name: 'defaultConnection', sourceType: 'connection' },
        });
      }
    }
    const connectionRequest = { ...prepConnection(data), source };
    if (data.integrationType === 'AZUREINGRESS') {
      connectionRequest.vendor = 'CUSTOM';
    }
    const connection = yield call(api.createItem, { entityType, data: connectionRequest });
    if (connection) {
      const authenticatedUser = yield select(getAuthenticatedUser);
      const activeAccount = yield select((state) => state.App.activeAccount);
      const userClone = cloneDeep(authenticatedUser);
      userClone.roles = activeAccount.roles.filter((role) => !role.includes('Super')); // filter is applied for removing super roles,present in old user accounts due to server issue
      const parsedPreferences = JSON.parse(userClone.preferences || '{}');
      if (!(parsedPreferences.getStarted || []).includes('connectData')) {
        yield put(userActions.addGettingStartedTask(userClone, userClone.id, 'connectData', activeAccount.id));
      }
      yield put({ type: actions.ADD_CONNECTION_SUCCESS, selectedItem: connection });
      yield put({ type: actions.FETCH_CONNECTIONS });
    }
    onFinish();
  });
}

export function* editConnectionsaga() {
  yield takeEvery(actions.EDIT_CONNECTION, function* ({ data, id, onFinish = () => {} }) {
    const connectionRequest = editConnectionPrep(data);
    if (data.integrationType === 'AZUREINGRESS') {
      connectionRequest.vendor = 'CUSTOM';
    }
    const connection = yield call(api.editItem, { entityType, data: connectionRequest, id });
    yield put({ type: actions.EDIT_CONNECTION_SUCCESS, selectedItem: connection });
    yield put({ type: actions.FETCH_CONNECTIONS });
    onFinish();
  });
}

// export function* deleteConnectionsaga() {
//   yield takeEvery(actions.DELETE__CONNECTION, function* () {

//   });
// }

export function* fetchBulUploadsSaga() {
  yield takeEvery(actions.FETCH_BULKUPLOADS, function* () {
    const data = yield call(api.fetchAll, { entityType: 'bulk-media', transform: transformConnections });
    if (data) {
      yield put({ type: actions.FETCH_BULKUPLOADS_SUCCESS, data });
    }
  });
}

export function* testConfiguration() {
  yield takeEvery(
    actions.TEST_CONFIGURATION,
    function* ({ isNewConnection, data, connectionType, onFinish, onSuccessValidation }) {
      try {
        let connectionTypeURL = '';

        if (isNewConnection) {
          yield put({ type: pciFlag.SET_TEMP_FORM_VALUES, data });
        }

        switch (connectionType) {
          case 'LIVEPERSON': {
            connectionTypeURL = '/liveperson';
            break;
          }
          case 'LIVEPERSONLIVECHAT': {
            connectionTypeURL = '/livepersonlivechat';
            break;
          }
          case 'LIVEPERSONAGENTSTATUS': {
            connectionTypeURL = '/livepersonagentstatus';
            break;
          }
          case 'AZUREINGRESS': {
            connectionTypeURL = '/azure/blob-and-queue';
            break;
          }
          default: {
            connectionTypeURL = '';
            break;
          }
        }

        const response = yield call(api.post, {
          url: `validate/connections${connectionTypeURL}`,
          data: prepConnection({
            ...data,
          }),
          msg: 'configuration tested successfully',
        });

        if (response.status === 200) {
          yield put({ type: pciFlag.TEST_CONFIGURATION_SUCCESS, data: { message: '' } });
          onSuccessValidation();
          // yield put({ type: actions.SET_TEMP_FORM_VALUES, data });
        } else {
          yield put({ type: pciFlag.TEST_CONFIGURATION_SUCCESS, data: { message: response.message } });
        }
        onFinish();
      } catch (err) {
        if (err) {
          yield put({ type: pciFlag.TEST_CONFIGURATION_SUCCESS, data: err });
          // yield put({ type: actions.SET_TEMP_FORM_VALUES, data });
          onFinish();
        }
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(changeConnectionViewSaga),
    fork(fetchConnectionsSaga),
    fork(addConnectionsaga),
    fork(editConnectionsaga),
    // fork(deleteConnectionsaga),
    fork(fetchBulUploadsSaga),
    fork(testConfiguration),
    fork(addAgentStatusConnection),
  ]);
}
