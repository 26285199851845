/** @prettier */
/* eslint-disable func-names */
import * as Yup from 'yup';
import get from 'lodash/get';
import includes from 'lodash/includes';

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, (list) => list && list.length === new Set(list.map(mapper)).size);
});

Yup.addMethod(Yup.array, 'uniqueInArray', function (message, path) {
  return this.test('unique', message, function (list) {
    const values = list.map((r) => get(r, path, '').toLowerCase());
    const duplicates = values.filter((value, index, self) => value && self.indexOf(value.toLowerCase()) !== index);
    if (duplicates.length === 0) return true;

    // find first duplicated.
    values.forEach((v, index) => {
      if (includes(duplicates, v)) {
        throw this.createError({
          path: `${this.path}[${index}].${path}`,
          message,
        });
      }
    });

    return true;
  });
});

const fields = {
  alphaNumeric: ['valid', 'Can only contain letters or numbers', (v) => !v || v.match(/^[a-zA-Z0-9]*$/g)],
  basicString: ['valid', 'Can only contain letters, numbers, - or _', (v) => !v || v.match(/^[a-zA-Z0-9-_]*$/g)],
  stringWithSpaces: [
    'valid',
    'Can only contain letters, numbers, -, _, . or spaces',
    (v) => !v || v.match(/^[a-zA-Z0-9-_ÁÉÍÓÚÜÑñáéíóúü¿¡. ]*$/g),
  ],
  stringWithSpacesAndCommas: [
    'valid',
    'Can only contain letters, numbers, -, _, ., comma or spaces',
    (v) => !v || v.match(/^[a-zA-Z0-9-_., ]*$/g),
  ],
  stringWithSpacesAndColon: [
    'valid',
    'Can only contain letters, numbers, -, _, ., :, or spaces',
    (v) => !v || v.match(/^[a-zA-Z0-9-:_. ]*$/g),
  ],
  advancedString: [
    'valid',
    'Allowed characters: letters, numbers, space, -, _, (), []',
    (v) => !v || v.match(/^[a-zA-Z0-9-_ ()[\].]*$/g),
  ],
  advancedString2: [
    'valid',
    'Can only contain letters, numbers, -, _, ., comma or spaces',
    (v) => !v || v.match(/^[a-zA-Z0-9-_.,:;'’/\\[\]<>()+*#$'&!\n\r ]*$/g),
  ],
  advancedString3WithQuotes: [
    'valid',
    'Can only contain letters, numbers, -, _, ., comma or spaces',
    (v) => !v || v.match(/^[a-zA-Z0-9-_.,:;"'’/\\[\]<>()+*#$'&!\n\r ]*$/g),
  ],
  metaDataMappingString: [
    'valid',
    'Allowed characters: letters, numbers, space, comma, #, (), {}, :, ?, /, !, =, _, -, ", +, \', [], $, ^',
    (v) => !v || v.match(/^[a-zA-Z0-9-!={}_ +#?()\\/,"':[\]$.^]*$/g),
  ],
  trimSpaces: [
    'valid',
    'Cannot start or end with spaces, nor have multiple spaces in a row',
    (v) => !v || v.match(/^\S+(?: \S+)*$/),
  ],
  trimSpacesWithLineReturn: [
    'valid',
    'Cannot start or end with spaces, nor have multiple spaces in a row',
    (v) => !v || (v.match(/^\S/) && v.match(/\S$/) && !v.match(/[^\S\r\n]{2,}/)),
  ],
  numbersOnly: ['valid', 'Can only contain numbers', (v) => !v || v.match(/^\d*$/g)],
  isValidJson: [
    'valid',
    'Must be valid JSON',
    (v) => {
      try {
        JSON.parse(v);
      } catch (e) {
        return false;
      }
      return true;
    },
  ],
  lettersNumbersCharacters: [
    'valid',
    'Can contain only numbers, letters and characters',
    (v) => !v || v.match(/^[a-zA-Z0-9!@,<>\\[?/;:|#\\$`%'"~^&}{*)(+=._ -[\]]*$/g),
  ],
  beginsWithValidCsvCharacter: [
    'valid',
    'Cannot begin with =, +, -, @, Return, or Tab',
    (v) => !v || !v.match(/^[\=\+\-\@\n\t]/),
  ],
  advancedStringChecklistText: [
    'valid',
    'Can only contain letters, numbers, -, _, ., ?, /, comma or spaces',
    (v) => !v || v.match(/^[a-zA-Z0-9-_ÁÉÍÓÚÜÑñáéíóúü¿¡.,:;/\\[\]<>()?+*#$'&!\n\r ]*$/g),
  ],
  spacesInBetween: ['valid', 'Cannot contain multiple spaces in between the words', (v) => !v || !v.match(/ +(?= )/g)],
  beginOrEndWithSpace: ['valid', 'Cannot begin or end with space', (v) => !v || !v.match(/^\s+|\s+$/g)],
  validUrl: [
    'valid',
    'Must be a valid url',
    (v) =>
      !v ||
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        v
      ),
  ],
};

export default fields;
